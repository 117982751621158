import axios from 'axios';

const headers = {
   'X-App-Name': 'demorzhd.2.mysimulator.ru'
    // 'X-App-Name': window.location.hostname
}

if (localStorage.getItem("token")){
    headers.Authorization = `Token ${localStorage.getItem("token")}`;
}

export default
    axios.create({
        baseURL: 'https://newapi.mysimulator.ru/api/',
        //baseURL: 'http://127.0.0.1:8000/api/',

        headers: headers    
    });
    
